/* eslint-disable @typescript-eslint/no-unused-vars */
import { InjectionToken, ValueProvider } from '@angular/core';
import { IFilter } from '@aston/foundation';
import { Observable, of } from 'rxjs';

export interface UserFiltersConnector {
	selectFiltersIsLoading: () => Observable<boolean>;
	selectFiltersError: () => Observable<string | null>;
	selectFiltersByDomain: (domain: string) => Observable<IFilter[][]>;
	selectCanSaveFilterForDomain: (domain: string) => Observable<boolean>;
	addFiltersToDomain: (domain: string, filters: IFilter[]) => void;
	removeFiltersFromDomain: (domain: string, filters: IFilter[], index?: number) => void;
}

export const USER_FILTERS_CONNECTOR = new InjectionToken<UserFiltersConnector>('USER_FILTERS_CONNECTOR', {
	providedIn: 'root',
	factory: () => ({
		selectFiltersIsLoading: () => { throw 'not connected' },
		selectFiltersError: () => { throw 'not connected' },
		currentPreferences: of({}),
		selectFiltersByDomain: (domain: string) => { throw 'not connected' },
		selectCanSaveFilterForDomain: (domain: string) => { throw 'not connected' },
		addFiltersToDomain: (domain: string, filters: IFilter[]) => { throw 'not connected' },
		removeFiltersFromDomain: (domain: string, filters: IFilter[], index?: number) => { throw 'not connected' },
	})
});

export const provideMockUserFiltersConnector = (): ValueProvider => (
	{ provide: USER_FILTERS_CONNECTOR,
		useValue: {
		selectFiltersIsLoading: () => of(false),
		selectFiltersError: () => of(null),
		currentPreferences: of({}),
		updatePreferences: () => of(null),
		selectFiltersByDomain: (domain: string) => of([]),
		selectCanSaveFilterForDomain: (domain: string) => of(true),
		addFiltersToDomain: (domain: string, filters: IFilter[]) => ({}),
		removeFiltersFromDomain: (domain: string, filters: IFilter[], index?: number) => ({}),
	}
})
